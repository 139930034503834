import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import Home from './pages/home'
import MyTerms from './pages/my-terms'
import StandardTerms from './pages/standard-terms'
// import DashboardApp from './pages/DashboardApp';
// import Products from './pages/Products';
// import Blog from './pages/Blog';
// import User from './pages/User';
import NotFound from './pages/Page404';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Home />,
    },
    {
      path: '/login',
      element: <Login />, 
    },
    {
      path: '/my-terms',
      element: <MyTerms />, 
    },
    {
      path: '/standard-terms',
      element: <StandardTerms/>,
      children : [
        { path: '/standard-terms/:id', element: <StandardTerms /> },
        { path: '/standard-terms/:id/:code', element: <StandardTerms /> },
      ] 
    },
    {
      path: '/404',
      element: <NotFound/>, 
    },
    // {
    //   path: '/',
    //   element: <LogoOnlyLayout />,
    //   children: [
    //     { path: 'login', element: <Login /> },
    //     { path: '404', element: <NotFound /> },
    //     { path: '/', element: <Navigate to="/dashboard" /> },
    //     { path: '*', element: <Navigate to="/404" /> }
    //   ]
    // },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
