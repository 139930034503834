import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  autoCompleteStyles: {
    width: 300,
    [theme.breakpoints.down('sm')]: {
      width: 200
    }
  },
  autoCompleteTextField: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));


export default function TermSelect(props) {
  const classes = useStyles();
  return (
    <Autocomplete
      {...props}
      disablePortal
      id="combo-box-demo"
      disableClearable
      onChange={props.onChange}
      className={classes.autoCompleteStyles}
      renderInput={(params) => (
        <TextField {...params} label={props.title} className={classes.autoCompleteTextField} />
      )}
    />
  );
}
