import React, { useState } from 'react';
import { Box } from '@mui/material';
import SearchBar from 'material-ui-search-bar';
import {makeStyles } from '@material-ui/core/styles';

// theme.spacing(2)
const useStyles = makeStyles((theme) => ({
  searchBarContainer: {
    maxWidth:'100%',
    borderRadius:theme.spacing(10),
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
    }
  },
  searchBarStyles : {
      maxWidth:'100%',
      boxShadow:'none',
      borderRadius:20
  }
}));

const SearchBarComponent = (props) => {
  const classes = useStyles();
  const {searchItem,onChange, onRequestSearch} = props
  return (
    <Box boxShadow={2} className={classes.searchBarContainer} >
      <SearchBar
        placeholder = 'Enter Email'
        value={searchItem}
        onChange={onChange}
        onRequestSearch={onRequestSearch}
        className={classes.searchBarStyles}
      />
    </Box>
  );
};

export default SearchBarComponent;
