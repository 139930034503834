import React, { useEffect, useState , useRef } from 'react';
import { Grid, Container, Typography, Checkbox, Box, Stack } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useLazyQuery, gql } from '@apollo/client';
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import Card from '../../components/Card';
import CreateOwnComponents from './createOwnComponents';
import { SearchBar, AppAppBar, Button, SnackBar } from '../../components';
import Page from '../../components/Page';
import { validateEmail } from '../../utils/regex';

/* Query for terms */

const TERMS_DATA = gql`
  query getTerms($language: String!) {
    termsData(language: $language) {
      category {
        name
      }
      defaultCategory
      location {
        country {
          name
        }
        defaultLocation
        versions {
          types {
            termType
            isDefaultType
            termSet {
              id
              termGroup {
                name
              }
              terms {
                description
              }
            }
          }
        }
      }
    }
  }
`;

const SEARCH_TERM = gql`
  query SearchTerm($language: String, $email: String) {
    searchTerm(language: $language, email: $email) {
      userId
      standardTerm {
        userTermUniqueCode
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    // width: '100%',
    // height: '80vh'
  },
  searchBarContainer: {
    width: '30%',
    marginTop: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      width: '70%'
    }
  },
  buttonContainer: {
    marginTop: theme.spacing(10),
    width:'100%',
    justifyContent:'center',
    
  },
  button: {
    height: 40,
    padding: 10,
    backgroundColor:'#00AB55'
  },
  SelectContainer: {
    margin: theme.spacing(10)
    // width: '100%'
  },
  selectSubContainer: {
    width: '50%',
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      width: '70%'
    }
  },
  cardStyles: {
    display: 'flex',
    margin: theme.spacing(10)
  },
  termsCardsStyle: {
    maxWidth: 500
  },
  termSelectionContainer:{
    marginTop:theme.spacing(10)
  }
}));

const Home = () => {
  const recaptchaRef = useRef();
  const { t, i18n } = useTranslation();
  const [getTerms, { data, error }] = useLazyQuery(TERMS_DATA, {
    variables: {
      language:i18n.language
    }
  });
  const [SearchTerm, { data: searchedData, error: SearchedError }] = useLazyQuery(SEARCH_TERM,{ fetchPolicy: 'network-only'});
  const [state, setState] = useState({
    currentSelectedTerm: {},
    currentCategory: '',
    currentCountry: '',
    currentTermType: '',
    allCategory: [],
    allCountry: [],
    allTerms: [],
    snackbarMessage: '',
    snackbarSeverity: 'success',
    isRoboChecked:false,
    showShouldRobo:false
  });
  const [searchedTerm, setSearchedTerm] = useState();
  const [isValidEmail, setValidEmail] = useState(true);
  const classes = useStyles();
  const store = useSelector((state) => state.AuthReducer);
  const navigate = useNavigate();

  useEffect(() => {
    getTerms();
  }, []);
  useEffect(() => {
 if(state.isRoboChecked) {
  const isEmailValid = validateEmail(searchedTerm);
  if(isEmailValid && searchedTerm) {
    SearchTerm({ variables: { language: 'en', email: searchedTerm } });
  }
 }
  },[state.isRoboChecked])

  useEffect(() => {
    if (!isEmpty(data)) {
      const defaultCat = getDefaultCategory(data.termsData);
      console.log('defaultCat', defaultCat);
      const allCats = getAllCats(data.termsData);
      setState((prevState) => ({
        ...prevState,
        currentSelectedTerm: defaultCat,
        allCategory: [...allCats]
      }));
    }
  }, [data, error]);

  useEffect(() => {
    const { currentSelectedTerm } = state;
    if (!isEmpty(currentSelectedTerm)) {
      const currentCategory = currentSelectedTerm.category.name;
      const currentLocation = getDefaultLocation(currentSelectedTerm.location).country.name;
      const allCountry = getAllCountries(currentSelectedTerm);
      const { term, currentAllTerms } = getDefaultTerms(currentSelectedTerm);
      setState((prevState) => ({
        ...prevState,
        currentCountry: currentLocation,
        currentCategory: currentCategory,
        currentTermType: term,
        allTerms: [...currentAllTerms],
        allCountry: [...allCountry]
      }));
    }
  }, [state.currentSelectedTerm]);

  useEffect(() => {
    if (SearchedError) {
      console.log('searchedData', searchedData, 'SearchedError', SearchedError);
      setState((prevState) => ({
        ...prevState,
        snackbarMessage: SearchedError.message,
        snackbarSeverity: 'error'
      }));
    }
    if (!isEmpty(searchedData)) {
      navigate(
        `/standard-terms/${searchedData.searchTerm.userId}/${searchedData.searchTerm.standardTerm.userTermUniqueCode}`
      );
    }
  }, [searchedData, SearchedError]);

  const getAllCats = (data) => {
    if (data && data.length > 0) {
      const res = data.map((item) => item.category.name);
      return res;
    }
    return null;
  };
  const getDefaultCategory = (allCats) => {
    if (allCats && allCats.length > 0) {
      console.log('allCats--->>>', JSON.stringify(allCats));
      const cat = allCats.filter((item) => item.defaultCategory === true);
      return cat[0];
    }
    return null;
  };

  const getDefaultLocation = (allLocs) => {
    if (allLocs && allLocs.length > 0) {
      const loc = allLocs.filter((item) => item.defaultLocation === true);
      return loc[0];
    }
    return null;
  };

  const getDefaultTerms = (currentTerm) => {
    const term = getDefaultLocation(currentTerm.location).versions[0].types[0].termType;
    const currentAllTerms = getDefaultLocation(currentTerm.location).versions[0].types[0].termSet;
    return { term, currentAllTerms };
  };

  const getAllCountries = (currentTerm) => {
    if (!isEmpty(currentTerm.location)) {
      const countries = currentTerm.location.map((item) => item.country.name);
      return countries;
    }
    return null;
  };
  const onChangeCategory = (val) => {
    if (!isEmpty(data)) {
      const currentSelectedTerm = data.termsData.filter((item) => item.category.name === val);
      setState((prevState) => ({
        ...prevState,
        currentSelectedTerm: currentSelectedTerm[0]
      }));
    }
  };

  const getCurrentTerms = (country, currentSelectedTerm) => {
    const res = currentSelectedTerm.location.filter((item) => item.country.name === country);
    console.log('resresresresres----->>>>', res);
    const currentTerm = res[0].versions[0].types[0].termType;
    const currentAllTerms = res[0].versions[0].types[0].termSet;
    return { currentTerm, currentAllTerms };
  };

  const onChangeCountry = (val) => {
    const { currentSelectedTerm } = state;
    const { term, currentAllTerms } = getCurrentTerms(val, currentSelectedTerm);
    setState((prevState) => ({
      ...prevState,
      currentCountry: val,
      currentTermType: term,
      allTerms: [...currentAllTerms]
    }));
  };

  const createYourOwnTerms = () => {
    if (isEmpty(store.user)) {
      navigate('/login');
    } else navigate('/my-terms');
  };

  const onChangeSearch = (val) => {
    setSearchedTerm(val);
  };

  const onRequestSearch = () => {
    setValidEmail(true);
    setState({...state,showShouldRobo:true})
    const isEmailValid = validateEmail(searchedTerm);
    console.log('isRoboChecked--->>>',state.isRoboChecked)
    if (isEmailValid && state.isRoboChecked) {
      console.log("searchedTerm---->>",searchedTerm)
      SearchTerm({ variables: { language: 'en', email: searchedTerm } });
    } else if(!isEmailValid){
      setValidEmail(false);
    }
  };
  const onChange = (val) => {
    setState({...state, isRoboChecked: !!val})
  }
  console.log('data---->>', JSON.stringify(data));
  return (
    <Page title="Terms First">
      <AppAppBar />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.container}
      >
        <Grid mt={10} alignContent="center">
          <Typography align="center" color="primary" variant="subtitle1">
            Welcome to Terms First, an information mediation company driven by your own terms
          </Typography>
          <Typography align="center" color="primary" variant="subtitle1">
            You can do 2 things here
          </Typography>
          <Box mt={5}>
            <Card>
              <Typography align="left" color="primary" variant="p">
                Before giving information to any online site, you can provide terms first link to
                them; once they are agree to your terms, you can exchange your information
              </Typography>
              <Typography align="left" color="primary">
                You can set own data terms and get a public link; start Publish in your exchange
                points(email,forms,websites, commerce sites)
              </Typography>
            </Card>
          </Box>
          <Box mt={2}>
            <Card>
              <Typography align="left" color="primary">
                View other person data terms, you can agree terms before communicating further
              </Typography>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={3} xl={12} className={classes.searchBarContainer}>
          <Box mb={2}>
            <Typography align="center" color="primary" variant="h4">
              Search other peoples Term
            </Typography>
          </Box>
          <SearchBar
            onChange={onChangeSearch}
            searchItem={searchedTerm}
            onRequestSearch={() => onRequestSearch()}
          />
          <Stack direction="row" m={2} spacing={2}>
            <Typography align="left">@Twitter</Typography>
            <Typography align="left">@LinkedIn</Typography>
            <Typography align="left">@Facebook</Typography>
          </Stack>
          <Stack direction="row" justifyContent="center" mt={2}>
            <Typography color="primary">{!isValidEmail && 'Invalid Email'}</Typography>
          </Stack>
          <Grid justifyContent="center" direction="row" mt={5} container>
            {state.showShouldRobo && (
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={`6Le_iJgdAAAAAKLhj_eb42wSYFdJ5IXs0H_VffQO`}
                onChange={onChange}
              />
            )}
          </Grid>
          <Grid justifyContent="center" direction="row" mt={5} container>
            {!isEmpty(store.user) && (
              <Button
                className={classes.button}
                color="inherit"
                onClick={() => navigate('/my-terms')}
              >
                Go To My Terms
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      {isEmpty(store.user) && (
        // <Card containerStyle={classes.cardStyles}>
        <Container className={classes.termSelectionContainer}>
          <Box mb={2}>
            <Typography align="center" color="primary" variant="h4">
              View Standard Data Terms
            </Typography>
          </Box>
          <Grid container spacing={2} direction="row" justifyContent="center">
            <Grid item>
              <CreateOwnComponents
                title={'Select the Terms'}
                value={state.currentCategory}
                options={state.allCategory}
                onChange={(item, val) => onChangeCategory(val)}
              />
            </Grid>
            <Grid item>
              <CreateOwnComponents
                title={'Select Country'}
                value={state.currentCountry}
                options={state.allCountry}
                onChange={(item, val) => onChangeCountry(val)}
              />
            </Grid>
          </Grid>
          <Grid container mt={5} direction="row" justifyContent="center">
            <Card containerStyle={classes.termsCardsStyle}>
              {state.allTerms &&
                state.allTerms.length > 0 &&
                state.allTerms.map((item) => {
                  return (
                    <Box mt={2}>
                      <Card>
                        <Grid container spacing={4}>
                          <Grid item>
                            <Typography>
                              <Box fontWeight="fontWeightBold">{item.termGroup.name}</Box>
                            </Typography>
                          </Grid>
                          <Grid item>
                            {item.terms &&
                              item.terms.length > 0 &&
                              item.terms.map((i) => {
                                return (
                                  <Grid container>
                                    <Typography>{i.description}</Typography>
                                    <Checkbox defaultChecked disabled />
                                  </Grid>
                                );
                              })}
                          </Grid>
                        </Grid>
                      </Card>
                    </Box>
                  );
                })}
            </Card>
          </Grid>
          <Grid
            container
            style={{ justifyContent: 'center', alignItems: 'center' }}
            mt={10}
            mb={10}
          >
            {isEmpty(store.user) && (
              <Grid item xs={3} xl={12} className={classes.buttonContainer} container>
                <Box>
                  <Button
                    className={classes.button}
                    color="inherit"
                    onClick={() => createYourOwnTerms()}
                    fullWidth
                  >
                    Publish My Terms
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
        // </Card>
      )}
      {state.snackbarMessage && (
        <SnackBar message={state.snackbarMessage} severity={state.snackbarSeverity} />
      )}
    </Page>
  );
};

export default Home;
