import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@mui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

function Alert(props) {
  return (
    <MuiAlert
      elevation={6}
      variant="filled"
      className={props.className}
      {...props}
    />
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  Snackbar: {
    width: '100%',
    height: 45,
    textAlign: 'center',
  },
  snackbarAlert: {
    margin: 10,
  },
}));

export default function PositionedSnackbar({ message, severity, ...rest }) {
  const [state, setState] = React.useState({
    open: true,
    vertical: 'top',
    horizontal: 'center',
  });
  const classes = useStyles();
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  return (
    <div className={classes.root}>
      <Snackbar
        open={state.open}
        autoHideDuration={3000}
        onClose={handleClose}
        transitionDuration={500}
        color='primary'
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          className={classes.snackbarAlert}
          color='primary'
        >
          <Typography style={{ alignSelf: 'center' }}>
            { message}
          </Typography>
        </Alert>
      </Snackbar>
    </div>
  );
}
Snackbar.defaultProps = {
  message: 'Something went wrong.',
  severity: 'success',
};
Snackbar.prototype = {
  message: PropTypes.string,
  severity: PropTypes.string,
};
