import { createSlice } from '@reduxjs/toolkit'

export const AuthReducer = createSlice({
  name: 'Auth',
  initialState: {
    user: {},
    defaultLanguage:'en'
  },
  reducers: {
    setInitialProfiles: (state,actions) => {
      state.user = actions.payload
    },
    changeLanguage:(state,actions) => {
      state.defaultLanguage = actions.payload
    },
    clearData:(state,actions) => {
      console.log("callled->>>>")
      state.user = {};
      localStorage.removeItem('token');
    },
  },
})

// Action creators are generated for each case reducer function
export const { setInitialProfiles, changeLanguage, clearData } = AuthReducer.actions

export default AuthReducer.reducer