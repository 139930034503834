export const uiConfig = firebase => {
    return {
        signInFlow: 'popup',
        signInSuccessUrl: '/my-terms',
        tosUrl: '/terms-of-service',
        privacyPolicyUrl: '/privacy-policy',
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            {
              provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
              scopes: [
                'public_profile',
                'email',
              ],
            },
            'microsoft.com',
            'apple.com'
        ] , 
        callbacks: {
            signInSuccessWithAuthResult: () => false,
            // signInSuccessWithAuthResult: function(authResult, redirectUrl) {
            //   // User successfully signed in.
            //   // Return type determines whether we continue the redirect automatically
            //   // or whether we leave that to developer to handle.
            //   return true;
            // },
            uiShown: function() {
              // The widget is rendered.
              // Hide the loader.
              //   document.getElementById('loader').style.display = 'none';
            }
          },
    }
}