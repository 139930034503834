// routes
import Router from './routes';
// theme
import ThemeConfig from 'theme';
import GlobalStyles from 'theme/globalStyles';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'
// components
import ScrollToTop from 'components/ScrollToTop';

import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react'
import { Provider } from 'react-redux';
import { store, persistor } from './store';

import { setContext } from '@apollo/client/link/context';
import { getItemToLocalStorage } from './utils/storage'

// ----------------------------------------------------------------------

const httpLink = createHttpLink({
  uri: 'https://dev.termsfirst.com/api/graphql'
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  console.log("apptoken-->>",token)
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null
    }
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false
  })
});

export default function App() {
  return (
    <ApolloProvider client={client}>
      <I18nextProvider i18n={i18n}>
      <Provider store={store}>
       <PersistGate loading={null} persistor={persistor}> 
        <ThemeConfig>
          <ScrollToTop />
          <GlobalStyles />
          <Router />
        </ThemeConfig>
        </PersistGate>
      </Provider>
      </I18nextProvider>
    </ApolloProvider>
  );
}
