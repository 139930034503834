import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, Stack } from '@mui/material';
import { useLazyQuery, gql, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isEmpty } from 'lodash';
import Page from '../../components/Page';
import Card from '../../components/Card';
import { AppAppBar, Button, SnackBar } from '../../components';

const useStyles = makeStyles((theme) => ({
  termsCardsStyle: {
    minWidth: '500'
  }
}));

const STANDARD_TERMS = gql`
  query StandardTerm($userId: ID!) {
    standardTerm(userId: $userId) {
      standardTerm {
        _id
        userTermUniqueCode
        category {
          name
        }
        defaultCategory
        location {
          versions {
            types {
              termSet {
                id
                termGroup {
                  name
                }
                terms {
                  description
                  termCode
                }
              }
            }
          }
        }
      }
      userName
      userEmail
      userId
    }
  }
`;

const STANDARD_TERMS1 = gql`
  query UserTerm($userId: ID!, $userTermUniqueCode: String!, $language: String) {
    userTerm(userId: $userId, userTermUniqueCode: $userTermUniqueCode, language: $language) {
      standardTerm {
        _id
        userTermUniqueCode
        category {
          name
        }
        defaultCategory
        location {
          versions {
            types {
              termSet {
                id
                termGroup {
                  name
                }
                terms {
                  description
                  termCode
                }
              }
            }
          }
        }
      }
      userName
      userEmail
      userId
    }
  }
`;

const APPROVE_TERMS = gql`
  mutation AckTerm($ackTermInput: ackTermInput!) {
    ackTerm(ackTermInput: $ackTermInput)
  }
`;

const StandardTerms = (props) => {
  let { id, code } = useParams();
  console.log('id-->>', id, 'code-->>', code);
  const [
    getStandardTerms,
    { data: standardTermData, error: standardTermError, loading: standardTermLoading }
  ] = useLazyQuery(STANDARD_TERMS, {
    variables: {
      userId: id
    },
    fetchPolicy: 'network-only'
  });

  const [
    getStandardTerms1,
    { data: standardTermData1, error: standardTermError1, loading: standardTermLoading1 }
  ] = useLazyQuery(STANDARD_TERMS1, {
    variables: {
      userId: id,
      userTermUniqueCode: code
    },
    fetchPolicy: 'network-only'
  });

  const [onApprove, { data: onApproveData, loading: onApproveLoading, error: onApproveError }] =
    useMutation(APPROVE_TERMS);

  console.log("standardTermError1---->>",standardTermError1)
  const store = useSelector((state) => state.AuthReducer);
  const navigate = useNavigate();
  const [fetchedTerms, setFetchedTerms] = useState({});
  const [state, setState] = useState({
    snackbarMessage: '',
    snackbarSeverity: 'success'
  });

  console.log('store-->>>>>', store);

  useEffect(() => {
    if (id && code) {
      console.log('second-->>', id, 'code-->>>', code);
      getStandardTerms1({variables : {userId:id,userTermUniqueCode:code}});
    }
    else {
      console.log('firstCalled-->>', id);
      getStandardTerms();
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(standardTermData)) {
      setFetchedTerms(standardTermData);
    }
    if (!isEmpty(standardTermData1)) {
      let data = {};
       data['standardTerm'] = standardTermData1.userTerm;
      setFetchedTerms(data);
    }
    console.log('standardTermData', JSON.stringify(standardTermData1));
  }, [standardTermData, standardTermData1]);

  useEffect(() => {
    console.log('onApproveData-->>>', onApproveData, 'onApproveError--->>', onApproveError);
    if (!isEmpty(onApproveData)) {
      if (onApproveData.ackTerm) {
        setState((prevState) => ({
          ...prevState,
          snackbarMessage: 'Approved',
          snackbarSeverity: 'success'
        }));
        setTimeout(() => {
          navigate('/my-terms');
        }, 500);
      } else {
        setState((prevState) => ({
          ...prevState,
          snackbarMessage: 'An Error Has Occured',
          snackbarSeverity: 'error'
        }));
      }
    }
  }, [onApproveData, onApproveLoading, onApproveError]);

  const showApproveButton = () => {
    if (isEmpty(store.user)) return true;
    else {
      if (store.user.id === id) return false;
      else return true;
    }
  };
  const onClickApprove = () => {
    if (isEmpty(store.user)) {
      navigate('/login');
    } else {
      onApprove({
        variables: {
          ackTermInput: {
            userId: id,
            userTermUniqueCode: fetchedTerms.standardTerm.standardTerm.userTermUniqueCode
            // userTermUniqueCode: code
          }
        }
      });
    }
  };

  if (standardTermLoading) {
    return (
      <Page title="Standard terms">
        <AppAppBar />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh', position: 'absolute' }}
        >
          <Grid item xs={3}>
            <CircularProgress size={23} color="primary" />
          </Grid>
        </Grid>
      </Page>
    );
  } else
    return (
      <Page title="Standard terms">
        <AppAppBar />
        <Box m={10}>
          <Grid container spacing={0} direction="column">
            {!isEmpty(fetchedTerms) && (
              <Card>
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item>
                    <Typography gutterBottom color="primary">
                      {fetchedTerms.standardTerm.userName}
                    </Typography>
                    <Typography gutterBottom color="primary">
                      {fetchedTerms.standardTerm.userEmail}
                    </Typography>
                    <Typography gutterBottom color="primary">
                      {fetchedTerms.standardTerm.standardTerm.category.name}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2} direction="column" alignItems="center">
                  {fetchedTerms.standardTerm.standardTerm.location.versions.types.termSet.map(
                    (item) => {
                      return (
                        <Box mt={2} sx={{ minWidth: 500 }}>
                          <Card>
                            <Grid container spacing={4}>
                              <Grid item>
                                <Typography>{item.termGroup.name}</Typography>
                              </Grid>
                              <Grid item>
                                {item.terms.map((i) => {
                                  return (
                                    <Grid container>
                                      <Typography>{i.description}</Typography>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </Grid>
                          </Card>
                        </Box>
                      );
                    }
                  )}
                </Grid>
                {showApproveButton() && (
                  <Stack direction="row" justifyContent="center" mt={4}>
                    <Button variant="contained" size="small" onClick={() => onClickApprove()}>
                      Approve
                    </Button>
                  </Stack>
                )}
                {state.snackbarMessage ? (
                  <SnackBar message={state.snackbarMessage} severity={state.snackbarSeverity} />
                ) : null}
              </Card>
            )}
          </Grid>
        </Box>
      </Page>
    );
};

export default StandardTerms;
