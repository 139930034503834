import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import clsx from 'clsx';

const styles = muiBaseTheme => ({
  card: {
    maxWidth: '100%',
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
    }
  },
  content: {
    textAlign: "left",
    padding: muiBaseTheme.spacing.unit * 3
  },
  divider: {
    margin: `${muiBaseTheme.spacing.unit * 3}px 0`
  },
});

function App(props) {
const { classes,children, containerStyle } = props
  return (
    <div className="App">
      <Card className={clsx(classes.card, containerStyle)} >
        <CardContent className={classes.content}>     
           {children}
        </CardContent>
      </Card>
    </div>
  );
}

export default withStyles(styles)(App);