import React from 'react';
import { Typography, Grid, Box, Button } from '@mui/material';
import { TermSelect } from './components';

const CreateOwnComponents = (props) => {
  const {title} = props;
  return (
    <div>
      <Grid container spacing={0} direction="column">
        <Typography variant="subtitle2">{title}</Typography>
      </Grid>
      <Box mt={2}>
        <TermSelect {...props}/>
      </Box>
    </div>
  );
};

export default CreateOwnComponents;
