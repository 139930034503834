import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@mui/material/Popover';
import {
  Box,
  MenuItem,
  ListItemText,
  ButtonBase,
  Link,
  Stack
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import AppBar from './AppBar';
import Toolbar from './Toolbar';
import { Button } from '../components';
import { clearData  } from '../store/reducer/authReducer';
import { firebase } from '../firebase';



const useStyles = makeStyles((theme) => ({
  titleStyles: {
    fontSize: theme.typography.h6,
    color: 'white'
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  imgStyle: {
    height: 22,
    width: 22,
    borderRadius: 11
  }
}));

const LANGS = [
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'pt',
    label: 'portuguese',
  },
];

function AppAppBar() {
  const classes = useStyles();
  const globalState = useSelector((state) => state.AuthReducer);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [AccountPopover, setAccountPopover] = React.useState(null);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickMenuItem = (e) => {
   i18n.changeLanguage(e.value)
  }

  const onLogout = () => {
    setAccountPopover(null)
    firebase.auth().signOut()
    dispatch(clearData());
    navigate('/')
  }
  const myTerms = () => {
    navigate('/my-terms')
  }
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const AccountPopoverOpen = Boolean(AccountPopover);
  const accountPopOverId = AccountPopover ? 'account-popover' : undefined;

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ flex: 1 }} />
           <ButtonBase onClick={() => navigate('/')}>
          <Link variant="h6" underline="none" color="inherit" className={classes.titleStyles}>
            {'Terms First'}
          </Link>
          </ButtonBase>
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
           <ButtonBase onClick={() => navigate('/')}>
              <Link
                variant="h6"
                underline="none"
                className={classes.titleStyles}
                sx={{ marginRight: 5 }}
              >
                {'Home'}
              </Link>
            </ButtonBase>
           {!isEmpty(globalState.user) && <ButtonBase onClick={() => navigate('/my-terms')}>
              <Link
                variant="h6"
                underline="none"
                className={classes.titleStyles}
                sx={{ marginRight: 5 }}
              >
                {'My Terms'}
              </Link>
            </ButtonBase>}
            <ButtonBase onClick={(e) => handleClick(e)}>
              <Link
                variant="h6"
                underline="none"
                className={classes.titleStyles}
                sx={{ marginRight: 5 }}
              >
                {'Lang'}
              </Link>
            </ButtonBase>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
            >
              <Box sx={{ padding:1, display:'flex', flexDirection:'column' }}>
                {LANGS.map((option) => (
                  <MenuItem
                    key={option.value}
                    selected={option.value === LANGS[0].value}
                    onClick={() => onClickMenuItem(option)}
                    sx={{ mt:5}}
                  >
                    <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                      {option.label}
                    </ListItemText>
                  </MenuItem>
                ))}
              </Box>
            </Popover>
            {isEmpty(globalState.user) ? (
              <Link
                to="/login"
                variant="h6"
                underline="none"
                color="inherit"
                component={RouterLink}
                className={classes.titleStyles}
              >
                {t('signIn')}
              </Link>
            ) : (
              <ButtonBase onClick={(e) => setAccountPopover(e.currentTarget)}>
              <div className={classes.profileContainer}>
                <Typography variant="body" marginRight={2}>
                  {globalState.user.name}
                </Typography>
                <img
                  className={classes.imgStyle}
                  alt="profile"
                  src={globalState.user.profilePhoto}
                />
              </div>
              </ButtonBase>
            )}
            <Popover
              id={accountPopOverId}
              open={AccountPopoverOpen}
              anchorEl={AccountPopover}
              onClose={() => setAccountPopover(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
            >
              <Box sx={{ padding:1, display:'flex', flexDirection:'column' }}>
              <Button size='small' onClick={() =>  onLogout()}>
                Logout 
              </Button>
              <Stack mt={2}>
              <Button size='small' onClick={() =>  myTerms()}>
                My Terms
              </Button>
              </Stack>
              </Box>
            </Popover>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}

export default AppAppBar;
