import { styled } from '@mui/material/styles';
import MuiToolbar from '@mui/material/Toolbar';



const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  height: 50,
  background: `linear-gradient(to right bottom, ${theme.palette.primary.lighter}, ${theme.palette.primary.light})`,
  [theme.breakpoints.up('sm')]: {
    height: 50,
  },
}));

export default Toolbar;