import React, { useEffect, useState } from 'react';
import { Grid, Container, Typography, Stack, Box, ButtonBase } from '@mui/material';
import { useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@material-ui/core/styles';
import { useLazyQuery, gql, useMutation } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isEmpty } from 'lodash';
import moment  from 'moment';
import Page from '../../components/Page';
import Card from '../../components/Card';
import { AppAppBar, Button, SnackBar } from '../../components';
import CreateOwnComponents from '../home/createOwnComponents';

const useStyles = makeStyles((theme) => ({
  container: {
    // width: '100%',
    // height: '80vh'
  },
  searchBarContainer: {
    width: '50%',
    marginTop: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      width: '70%'
    }
  },
  buttonContainer: {
    marginTop: theme.spacing(10),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    height: 40,
    padding: 10
  },
  SelectContainer: {
    margin: theme.spacing(10)
    // width: '100%'
  },
  selectSubContainer: {
    width: '50%',
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      width: '70%'
    }
  },
  cardStyles: {
    display: 'flex',
    flexDirection:'row',
    justifyContent:'center',
    margin: theme.spacing(10)
  },
  termsCardsStyle: {
    maxWidth: 500
  },
  myTermsCardStyle: {
    marginTop: theme.spacing(1),
    padding:theme.spacing(2),
    position: 'relative'
  }
}));

const TERMS_DATA = gql`
  query getTerms($language: String!) {
    termsData(language: $language) {
      category {
        name
      }
      defaultCategory
      location {
        country {
          name
        }
        defaultLocation
        versions {
          types {
            termType
            isDefaultType
            _id
            termSet {
              termGroup {
                name
              }
              terms {
                description
              }
            }
          }
        }
      }
    }
  }
`;

const MY_TERMS = gql`
  query getMyTerms($language: String!) {
    userTerms(language: $language) {
      isDefault
      userTermUniqueCode
      termCreationDate
      category {
        name
      }
      agreedBy {
        user {
          name
        }
      }
      location {
        country {
          name
        }
        versions {
          version
          types {
            termType
            _id
            termSet {
              id
              termGroup {
                name
              }
              terms {
                description
              }
            }
          }
        }
      }
    }
  }
`;

const CREATE_TERMS = gql`
  mutation CreateTerm($createTermInput: createTermInput!) {
    createTerm(createTermInput: $createTermInput)
  }
`;

const MyTerms = () => {
  const [getAllTerms, { data: myTermsData, error: myTermsError, loading: getTermLoading }] =
    useLazyQuery(MY_TERMS, {
      variables: {
        language: 'en'
      },
      fetchPolicy: 'network-only'
    });
  const [getTerms, { data, error, loading }] = useLazyQuery(TERMS_DATA, {
    variables: {
      language: 'en'
    }
  });

  const [
    createTerms,
    { data: createTermsData, loading: createTermsLoading, error: createTermsError }
  ] = useMutation(CREATE_TERMS);
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const [userTerms, setUserTerms] = useState([]);
  const [shouldAllowToCreateTerm, setShouldAllowToCreateTerm] = useState(false);
  const store = useSelector((state) => state.AuthReducer);
  const [state, setState] = useState({
    currentSelectedTerm: {},
    currentCategory: '',
    currentCountry: '',
    currentTermType: '',
    defaultTermCreationID: '',
    allCategory: [],
    allCountry: [],
    allTerms: [],
    snackbarMessage: '',
    snackbarSeverity: 'success'
  });
  const classes = useStyles();
  const navigate = useNavigate();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    getTerms();
  }, []);

  useEffect(() => {
    getAllTerms();
  }, []);

  useEffect(() => {
    if (!isEmpty(myTermsData)) {
      setUserTerms(myTermsData.userTerms);
    }
  }, [myTermsData, myTermsError]);

  console.log('myTermsData--->>>', myTermsData, 'myTermsError--->>', myTermsError);
  useEffect(() => {
    console.log('data------>>>', JSON.stringify(data));
    if (!isEmpty(data)) {
      const defaultCat = getDefaultCategory(data.termsData);
      const allCats = getAllCats(data.termsData);
      setState((prevState) => ({
        ...prevState,
        currentSelectedTerm: defaultCat,
        allCategory: [...allCats]
      }));
    }
  }, [data, error]);

  useEffect(() => {
    const { currentSelectedTerm } = state;
    if (!isEmpty(currentSelectedTerm)) {
      const currentCategory = currentSelectedTerm.category.name;
      const currentLocation = getDefaultLocation(currentSelectedTerm.location).country.name;
      const allCountry = getAllCountries(currentSelectedTerm);
      const { term, currentAllTerms } = getDefaultTerms(currentSelectedTerm);
      const defaultTermCreationID = getDefaultCreatedId(currentSelectedTerm);
      setState((prevState) => ({
        ...prevState,
        currentCountry: currentLocation,
        currentCategory: currentCategory,
        currentTermType: term,
        defaultTermCreationID: defaultTermCreationID,
        allTerms: [...currentAllTerms],
        allCountry: [...allCountry]
      }));
    }
  }, [state.currentSelectedTerm]);

  useEffect(() => {
    if (!isEmpty(createTermsData) && createTermsData.createTerm) {
      getAllTerms();
      setState((prevState) => ({
        ...prevState,
        snackbarMessage: 'Terms has Created',
        snackbarSeverity: 'success'
      }));
      setShouldAllowToCreateTerm(false);
    }
    if (createTermsError) {
      setState((prevState) => ({
        ...prevState,
        snackbarMessage: createTermsError.message,
        snackbarSeverity: 'Failure'
      }));
    }
  }, [createTermsError, createTermsData]);

  const getAllCats = (data) => {
    if (data && data.length > 0) {
      const res = data.map((item) => item.category.name);
      return res;
    }
    return null;
  };
  const getDefaultCategory = (allCats) => {
    if (allCats && allCats.length > 0) {
      const cat = allCats.filter((item) => item.defaultCategory === true);
      return cat[0];
    }
    return null;
  };

  const getDefaultLocation = (allLocs) => {
    if (allLocs && allLocs.length > 0) {
      const loc = allLocs.filter((item) => item.defaultLocation === true);
      return loc[0];
    }
    return null;
  };

  const getDefaultTerms = (currentTerm) => {
    const term = getDefaultLocation(currentTerm.location).versions[0].types[0].termType;
    const currentAllTerms = getDefaultLocation(currentTerm.location).versions[0].types[0].termSet;
    return { term, currentAllTerms };
  };

  const getAllCountries = (currentTerm) => {
    if (!isEmpty(currentTerm.location)) {
      const countries = currentTerm.location.map((item) => item.country.name);
      return countries;
    }
    return null;
  };

  const getDefaultCreatedId = (defaultCat) => {
    const defaultLoc = getDefaultLocation(defaultCat.location);
    const defaultTermCreationID = defaultLoc.versions[0].types[0]._id;
    return defaultTermCreationID;
  };

  const onChangeCategory = (val) => {
    if (!isEmpty(data)) {
      const currentSelectedTerm = data.termsData.filter((item) => item.category.name === val);
      setState((prevState) => ({
        ...prevState,
        currentSelectedTerm: currentSelectedTerm[0]
      }));
    }
  };

  const getCurrentTerms = (country, currentSelectedTerm) => {
    const res = currentSelectedTerm.location.filter((item) => item.country.name === country);
    console.log('resresresresres----->>>>', res);
    const currentTerm = res[0].versions[0].types[0].termType;
    const currentAllTerms = res[0].versions[0].types[0].termSet;
    return { currentTerm, currentAllTerms };
  };

  const getTermSetIdAccToCountry =  (val,currentSelectedTerm) => {
    const res = currentSelectedTerm.location.filter((item) => item.country.name === val);
    return res[0].versions[0].types[0]._id
  }

  const onChangeCountry = (val) => {
    const { currentSelectedTerm } = state;
    const { term, currentAllTerms } = getCurrentTerms(val, currentSelectedTerm);
    const currentTermSetIDAccToCountry = getTermSetIdAccToCountry(val,currentSelectedTerm)
    setState((prevState) => ({
      ...prevState,
      currentCountry: val,
      currentTermType: term,
      allTerms: [...currentAllTerms],
      defaultTermCreationID:currentTermSetIDAccToCountry
    }));
  };

  const createTerm = () => {
    console.log('state.defaultTermCreationID-->>>', state.defaultTermCreationID);
    createTerms({
      variables: {
        createTermInput: {
          termSetId: state.defaultTermCreationID
        }
      }
    });
  };

  const onAddNew = () => {
    setShouldAllowToCreateTerm(true);
  };

  const onCopyLink = (item) => {
    if (item.isDefault === 'true') {
      navigator.clipboard.writeText('https://dev.termsfirst.com/standard-terms/' + store.user.id);
    } else {
      navigator.clipboard.writeText(
        'https://dev.termsfirst.com/standard-terms/' + store.user.id + '/' + item.userTermUniqueCode
      );
    }
  };
  const viewAsPublic = item => {
    if (item.isDefault === 'true') {
      navigate('/standard-terms/' + store.user.id);
    }
    else {
      navigate('/standard-terms/' + store.user.id + '/' + item.userTermUniqueCode);
    }
  }

  // const filedDate = (date) => {
  //   const currentDate = new Date(parseInt(date));
  //   const month = currentDate.getMonth();
  //   const day = currentDate.getDate();
  //   const year = currentDate.getFullYear();
  //   return `${months[month]}-${day}-${year}`;
  // };
  return (
    <Page title="My Terms">
      <AppAppBar />
      {loading || getTermLoading || createTermsLoading ? (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh', position: 'absolute' }}
        >
          <Grid item xs={3}>
            <CircularProgress size={23} color="primary" />
          </Grid>
        </Grid>
      ) : null}
      <Box mt={3}>
        <Grid container spacing={0} direction="column">
          <Grid item xs={3} margin={10}>
            {userTerms &&
              userTerms.length > 0 &&
              userTerms.map((item, index) => {
                return (
                  <Card containerStyle={classes.myTermsCardStyle} sx={{ backgroundColor: 'green' }}>
                    <Accordion
                      style={{ backgroundColor: item.isDefault === 'true' ? '#C8FACD' : 'white' }}
                      expanded={expanded === `panel${index}`}
                      onChange={handleChange(`panel${index}`)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Stack direction="column" sx={{ width: '33%' }}>
                          <Typography variant="subtitle1" color="primary">
                            Category
                          </Typography>
                          <Typography variant="body2">{item.category.name}</Typography>
                        </Stack>
                        <Stack direction="column" alignItems="center">
                          <Typography variant="subtitle1" color="primary">
                            Country
                          </Typography>
                          <Typography variant="body2">{item.location.country.name}</Typography>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Stack>
                          <Stack direction="row" justifyContent="space-between">
                            <Typography variant="body2" color="primary">
                              Country
                            </Typography>
                            <Typography variant="body2">{item.location.country.name}</Typography>
                          </Stack>
                          <Stack direction="row" justifyContent="space-between">
                            <Typography variant="body2" color="primary">
                             Created Date 
                            </Typography>
                            <Typography variant="body2" sx={{ marginLeft:2}}>{new Date(parseInt(item.termCreationDate)).toISOString().slice(0,10)}</Typography>
                          </Stack>
                          {item.agreedBy && item.agreedBy.length > 0 && (
                            <Stack direction="column">
                              <Typography variant="subtitle2" >Agreed By</Typography>
                              {item.agreedBy.map((item) => (
                                <Typography variant="body2" color="primary">
                                  {item.user.name}
                                </Typography>
                              ))}
                            </Stack>
                          )}
                        </Stack>
                        <Stack direction="row">
                          <Stack m={1}>
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => onCopyLink(item)}
                            >
                              Copy Link
                            </Button>
                          </Stack>
                          <Stack m={1}>
                            <Button variant="contained" size="small" onClick={() => viewAsPublic(item)}>
                              View As Public
                            </Button>
                          </Stack>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                    {index === userTerms.length - 1 && (
                      <Stack sx={{ position: 'absolute', top: 0, right: 0 }} p={1}>
                        <ButtonBase onClick={() => onAddNew()}>
                         <Box sx={{ boxShadow: 10, borderRadius:1 }} p={0.5}>
                        <AddIcon color="primary" />
                          </Box>
                        </ButtonBase>
                      </Stack>
                    )}
                  </Card>
                );
              })}
          </Grid>
        </Grid>
        {shouldAllowToCreateTerm && (
          <Card containerStyle={classes.cardStyles}>
            <Container >
              <Grid container spacing={2} direction="row" justifyContent="center">
                <Grid item>
                  <CreateOwnComponents
                    title={'Select the Terms'}
                    value={state.currentCategory}
                    options={state.allCategory}
                    onChange={(item, val) => onChangeCategory(val)}
                  />
                </Grid>
                <Grid item>
                  <CreateOwnComponents
                    title={'Select Country'}
                    value={state.currentCountry}
                    options={state.allCountry}
                    onChange={(item, val) => onChangeCountry(val)}
                  />
                </Grid>
              </Grid>
              <Grid container mt={5} direction="row" justifyContent="center">
                  <Card containerStyle={classes.termsCardsStyle}>
                    {state.allTerms &&
                      state.allTerms.length > 0 &&
                      state.allTerms.map((item) => {
                        return (
                          <Box mt={2}>
                            <Card>
                              <Grid container spacing={4}>
                                <Grid item>
                                  <Typography>{item.termGroup.name}</Typography>
                                </Grid>
                                <Grid item>
                                  {item.terms &&
                                    item.terms.length > 0 &&
                                    item.terms.map((i) => {
                                      return (
                                        <Grid container>
                                          <Typography>{i.description}</Typography>
                                        </Grid>
                                      );
                                    })}
                                </Grid>
                              </Grid>
                            </Card>
                          </Box>
                        );
                      })}
                  </Card>
                </Grid>
              <Stack direction="row" justifyContent="center" alignItems="center" mt={4}>
                <Button variant="contained" size="small" onClick={() => createTerm()}>
                  Create Terms
                </Button>
              </Stack>
            </Container>
          </Card>
        )}
      </Box>
      {state.snackbarMessage ? (
        <SnackBar message={state.snackbarMessage} severity={state.snackbarSeverity} />
      ) : null}
    </Page>
  );
};

export default MyTerms;
