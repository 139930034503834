import { createStore, combineReducers } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'reduxjs-toolkit-persist'
import storage from 'reduxjs-toolkit-persist/lib/storage' // defaults to localStorage for web
import { AuthReducer} from './reducer'

const persistConfig = {
  key: 'root',
  storage,
}

const reducers = combineReducers({
  AuthReducer: AuthReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);
let store = createStore(persistedReducer);
let persistor = persistStore(store);
export {store ,persistor}


