import React ,{ useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { gql, useMutation } from '@apollo/client';
import { Card, Stack, Container, Typography } from '@mui/material';
import Page from 'components/Page';
import { MHidden } from 'components/@material-extend';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { uiConfig } from '../config/firebase-ui';
import { fAuth, firebase }  from '../firebase';
import { setInitialProfiles } from '../store/reducer/authReducer';


const LOGIN = gql`
  mutation Login {
    login {
      name
      email
      profilePhoto
      firebaseUid
      id
    }
  }
`;


const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));



function Login(props) {
  const authUiConfig = uiConfig(firebase);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [hitLogin, { data }] = useMutation(LOGIN);

  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(userCred => {
      if(userCred) {
        userCred.getIdToken().then((token) => {
         localStorage.setItem('token',token);
          hitLogin();
          console.log("token--->>>>>>", token)     
        });
      }
    });
    return () =>unregisterAuthObserver();
  },[]);


useEffect(() => {
  if(!isEmpty(data)) {
    dispatch(setInitialProfiles(data.login));
    navigate(-1);
  }
},[data])

  return (
    <RootStyle title="Login | Minimal-UI">
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome 
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom align="center">
              Sign in to Terms First
            </Typography>
          </Stack>
          <StyledFirebaseAuth uiConfig={authUiConfig} firebaseAuth={fAuth}/>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}

export default Login;