import firebase from 'firebase/app';
import 'firebase/auth'
import { firebaseConfig } from './config/firebase'


if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig)
}

export const fAuth = firebase.auth();
export {firebase}